import React from 'react';
import { Card, Image, Text, Badge, Button, Group, useMantineTheme,Tabs, Tab } from '@mantine/core';
import { FiPhone,FiHome,FiBriefcase,FiMail } from 'react-icons/fi';
import {GoInfo,GoQuestion, GoNote} from 'react-icons/go';
import logo from './assets/img/laboh_logo.png'
import truck from './assets/img/truckcr.jpg';
import { height } from '@mui/system';
import { useMediaQuery } from '@material-ui/core';


const bkground  = ()=>(
    <Image
        radius="md"
        src={truck}
        style={{width: "100%"}}
        >
    </Image>)

export default function Banner(){
    const match = useMediaQuery('(min-width: 900px)');
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];


    return (
        <>
            <div style={{width:"100%",height:"100%",backgroundColor:"#0275d8"}}>
            <Card style={{backgroundColor:"#0275d8"}}>
                    <Image
                    radius="md"
                    src={truck}
                    style={{ width: "auto",maxWidth:"1000px", height:"auto",margin: 'auto'}}
                    >
                    </Image>
            </Card>
                   
                <div style={{width:"80%", margin:"auto"}} >
                {bkground}

                    <Card shadow="xl" 
                    style={{height:"1000px"}}>
                        
                        <Tabs grow>
                        <Tab label={match ? 'About Us' : ''} icon={<GoInfo/>}>
                            <Card shadow="sm"  style={{margin:"auto"}}> 
                                <Image
                                    radius="md"
                                    src={logo}
                                    style={{ width: "280px",height:"200px", margin: 'auto',marginTop:"-50px"}}>
                                </Image>   
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text size="lg" weight={700}>About Us:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                    <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                    Labohlano trading is a (Pty) Ltd Company.  Labohlano’s core business,
                                    is the selling and distribution of Propane and 
                                    Liquid petroleum gas, into the wholesale and retail markets.  
                                    The company is situated on the farm Saltberry Plain, approx. 6 km 
                                    from the Sasolburg CBD, Free State.  The management of Labohlano trading has 
                                    more than 40 years’ experience.  A filling plant exits to refill 
                                    Labohlano’s own imported and branded cylinders.  A bulk storage 
                                    plant is also available to facilitate a constant supply of product 
                                    throughout the year.
                                    </Text>
                                </Group>

                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }} align="left">
                                    <Text size="lg" weight={700}>Organizational Background:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }} align="left">
                                    <Text size="lg" weight={700}>Mission Statement:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                    <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5}} align="left">
                                        Labohlano Trading is committed the sale and distribution of Propane and LP Gas at a competitive market price.
                                        We service both the established and rural communities and promoting social up-liftment, while upholding the highest standards of safety and quality. 
                                    </Text>
                                </Group>
                            </Card>
                            
                        </Tab>
                        <Tab label={match ? 'Mission' : ''} icon={<GoQuestion/>}>
                        <Card shadow="sm" style={{height:"900px"}}>
                                
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text size="lg" weight={700}>Mission Statement:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                Labohlano trading is committed to the sale and distribution of propane and LP gas at a competitive market price.  
                                We service both the established and rural communities and promote social up-liftment,
                                 while upholding the highest standards of safety and quality.
                                </Text>
                                </Group>
                                <Image
                                    radius="md"
                                    src={logo}
                                    style={{ width: "280px",height:"200px", margin: 'auto' }}></Image>
                            </Card>
                        </Tab>
                        <Tab label={match ? "Policy Statement" : ''} icon={<GoNote/>}>
                            <Card shadow="sm"  style={{height:"900px"}}>
                                
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={700}>POLICY STATEMENT:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 2, marginTop: theme.spacing.xs }}>
                                <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 2 }} align="left">
                                We at Perfect Gas (Pty) Ltd will continuously strive to:</Text></Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text  size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 2 }} align="left">
                                   <ul>
                                    <li>Provide a clean, safe, and healthy working environment.</li>
                                     <li>
                                        It will be the duty and responsibility of every employee to take appropriate care of 
                                        their own SHEQ and those who may be affected by their action.  They must report all potential hazards to the employer.
                                     </li>
                                     <li>
                                         Provide training for all employees to enable and encourage them to perform their jobs efficiently with special emphasis on their commitment to safety, health, environment, and quality.
                                     </li>
                                     <li>
                                         Use the SQAS (safety, Health, Environment and quality) system as a basis for our own systems, which will include behavior-based safety.
                                     </li>
                                     <li>
                                         Create systems for effective environmental management.
                                     </li>
                                     <li>
                                         To meet our customer’s expectations and always provide exemplary service.
                                     </li>
                                    </ul>   
                                </Text>
                                </Group>
                                <Image
                                    radius="md"
                                    src={logo}
                                    style={{ width: "280px",height:"200px", margin: 'auto' }}></Image>
                            </Card>
                        </Tab>
                        <Tab label={match ? 'Management' : ''} icon={<FiBriefcase/>}>
                        <Card shadow="sm" style={{height:"900px"}}>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={700}>Management Team:</Text>
                                </Group>
                                <Card shadow="sm">
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Director of Operation	             :  Allan Peeters & Dawie Cloete
                                        </Text>
                                    </Group>
                                </Card>
                                <Card shadow="sm">
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{width:"700px", color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Chief Executive Officer	             :  Elaine Cloete
                                        </Text>
                                    </Group>
                                </Card>
                                <Card shadow="sm">
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Financial Director : Louise Peeters
                                        </Text>
                                    </Group>
                                </Card>
                                <Card shadow="sm" >
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Director of Sales and Marketing	: Willem Carstens
                                        </Text>
                                    </Group>
                                </Card>
                 
                                <Image
                                    radius="md"
                                    src={logo}
                                
                                    style={{ width: "280px",height:"200px", margin: 'auto' }}></Image>
                            </Card>
                        </Tab>
                        {/* <Tab label="Market Plan" icon={<FiBriefcase/>}>
                        <Card shadow="sm" padding="lg" style={{height:"900px"}}>
                                
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={700}>Pricing:</Text>
                                </Group>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                <Text size="md" style={{width:"700px", color: secondaryColor, lineHeight: 1.5 }} align="left">
                                    
                                The Gas market is driven primarily by a combination of supply and demand as well as the oil price. The market has also been identified as seasonal which is tied to the physical seasons (i.e. summer and winter). Pricing is also subject to the pricing of the supplier as Labohlano Trading is a secondary distributor.
                                The price breakdown is as follows:
                                    Selling Price = Purchase price + mark-up + transportation + VAT
                                </Text>
                                </Group>
                                <Image
                                    radius="md"
                                    src={logo}
                                    position={"center"}
                                    style={{ width: "280px",height:"200px", margin: 'auto' }}></Image>
                            </Card>
                        </Tab> */}
                        <Tab label={match ? 'Contact' : ''} icon={<FiPhone/>}>
                            <Card shadow="sm"  style={{height:"900px"}}>
                                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                                    <Text weight={700}>Contact Details <FiMail/></Text>
                                </Group>
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                        Office:
                                        <br/>
                                        -<FiPhone/>	<a href="tel:+016-9766529">016 976 6529</a> 
                                        <br/>
                                        -<FiPhone/>   <a href="tel:+016-9764618">016 976 4618</a> 
                                        </Text>
                                    </Group>
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Office-Cell:
                                            <br/>
                                            -<FiPhone/>	<a href="tel:+27760859668">076 085 9668</a> 
                                        </Text>
                                    </Group>
                                    <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.xs }}>
                                        <Text size="lg" style={{ color: secondaryColor, lineHeight: 1.5}} align="left">
                                            Email:
                                            <br/>
                                            <FiMail/><a href="mailto:orders@perfectgas.co.za">orders@perfectgas.co.za</a>
                                        </Text>
                                    </Group>

                                    <Image
                                    radius="md"
                                    src={logo}
                                    style={{ width: "280px",height:"200px", margin: 'auto' }}></Image>
                            </Card>
                        </Tab>
                        </Tabs>
                    </Card>
                </div>
            </div>
        </>
    )
}